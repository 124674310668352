<template>
  <NoPositionsStyled>
    {{ $tc('messages.notSupported') }}
  </NoPositionsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { flexCenter } from '@styles/mixins'

const NoPositionsStyled = styled('div')`
  ${flexCenter}
  width: 100%;
  height: 100%;
`
export default {
  components: {
    NoPositionsStyled,
  },
}
</script>
